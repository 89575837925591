import { FONT_WEIGHT } from "../foundations/textLayers";

const baseStyle = {
  bg: "linear-gradient(#FDDB5C, #AB8F39)",
  fontWeight: FONT_WEIGHT.SEMI,
  fontSize: [14, 18, 20, 25],
  w: ["100%", "65%"],
  borderRadius: 10,
  borderWidth: 0,
  boxShadow: "0px 0px 10px 4px rgba(224,169,53,1)",
  _hover: {
    bg: "linear-gradient(#AB8F39, #FDDB5C)",
    transitionDuration: "0.2s",
    transitionTimingFunction: "ease",
  },
  transitionDuration: "0.2s",
  transitionTimingFunction: "ease",
};

const sizes = {
  md: {
    px: 8,
    py: 7,
  },
};

const defaultProps = {
  variant: "base",
  // variant: "primary",
  // size: "md",
};

const buttonConfig = { baseStyle, sizes, defaultProps };

export default buttonConfig;
