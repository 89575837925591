import "./whatsappbutton.css";
import { BsWhatsapp } from "react-icons/bs";

const WhatsappButton = () => {
  const openLink = (event, text) => {
    text = text || "Olá! Quero tirar algumas dúvidas!";
    // const url = window.location.href;
    const phone = "5583987737155";
    window.open(
      `https://api.whatsapp.com/send?phone=${phone}&text=${text}`,
      "_blank"
    );
  };

  return (
    <div
      onClick={(e, text) => openLink(e, text)}
      target="_blank"
      rel="noreferrer"
      className="wrapper-whatsapp"
    >
      <BsWhatsapp style={{ marginTop: 0, fontSize: 28 }} />
      <span className="span-whatsapp">Dúvidas? Contate-nos.</span>
    </div>
  );
};

export default WhatsappButton;
