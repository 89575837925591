import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import colors from "../../assets/theme/foundations/colors";
import {
  FaMapPin,
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";

const text = "Olá! Quero tirar algumas dúvidas!";
const phone = "5583987737155";

const Footer: React.FC = () => {
  const redirectTo = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <Box
        bg="rgb(0 ,0, 0, 1)"
        w="100vw"
        zIndex={1000}
        px={{ base: 5, lg: 20 }}
        py={3}
      >
        <Flex
          justify={{ base: "center", lg: "space-between" }}
          flexDirection={{ base: "column", lg: "row" }}
          py={10}
        >
          <VStack
            cursor="pointer"
            pb={{ base: 10, lg: 0 }}
            onClick={() => redirectTo("https://br5beneficios.com.br/")}
          >
            <Image
              w={["120px", "140px", "180px"]}
              src="./assets/images/logo-1.webp"
              loading="lazy"
            />
            <Text fontWeight="bold">BR5 TURBO PLATAFORMA DIGITAL LTDA</Text>
            <Text fontWeight="bold">CNPJ: 12.233.290/0001-00</Text>
          </VStack>
          <VStack
            cursor="pointer"
            pb={{ base: 10, lg: 0 }}
            onClick={() =>
              redirectTo(
                "https://www.google.com.br/maps/place/BR5+Benef%C3%ADcios/@-15.5985332,-56.0885755,17z/data=!3m1!4b1!4m6!3m5!1s0x939db1d633bdae27:0x1403d07936e33b91!8m2!3d-15.5985332!4d-56.0863868!16s%2Fg%2F11qnl4tyxm"
              )
            }
          >
            <VStack textAlign="center">
              <FaMapPin color="#fff" fontSize={30} />
              <Text fontWeight="bold">João Pessoa - PB</Text>
              <Text>Rua Antonio Rabelo Junior 161, Ed. EcoBusines Center</Text>
              <Text>Sala 1906 - Bairro Miramar</Text>
              <Text>CEP: 58.032-090</Text>
            </VStack>
          </VStack>
          <VStack
            cursor="pointer"
            pb={{ base: 10, lg: 0 }}
            onClick={() =>
              redirectTo(
                `https://api.whatsapp.com/send?phone=${phone}&text=${text}`
              )
            }
          >
            <FaWhatsapp color="#fff" fontSize={30} />
            <Text fontWeight="bold">0800 006 8402</Text>
          </VStack>
          <VStack pb={{ base: 10, lg: 0 }}>
            <Text fontWeight="bold">Siga-nos em nossas redes</Text>
            <HStack>
              <Box
                cursor="pointer"
                onClick={() =>
                  redirectTo("https://www.facebook.com/br5beneficios/")
                }
                px={3}
              >
                <FaFacebook color="#fff" fontSize={25} />
              </Box>
              <Box
                cursor="pointer"
                px={3}
                onClick={() =>
                  redirectTo("https://www.instagram.com/br5turbo/")
                }
              >
                <FaInstagram color="#fff" fontSize={25} />
              </Box>
              <Box
                cursor="pointer"
                px={3}
                onClick={() => redirectTo("https://www.youtube.com/@br5turbo")}
              >
                <FaYoutube color="#fff" fontSize={25} />
              </Box>
            </HStack>
          </VStack>
        </Flex>
        <Center>
          <Text
            fontSize={{ base: 10, lg: 12 }}
            textAlign="center"
            color="rgb(241 ,216, 132, 0.5)"
          >
            A BR5 Turbo Plataforma Digital é uma LICENCIANTE limitada à divulgação dos Produtos,
            não sendo ela corretora de seguros, consultora financeira e nem exerce qualquer
            atividade diversa da simples divulgação dos produtos listados na plataforma digital,
            sendo que a venda e/ou negócio é realizado diretamente com a Empresa Parceira responsável
            pelo produto anunciado.
          </Text>
        </Center>
      </Box>
      <Box bg={colors.primary[600]} w="100vw" zIndex={1000} px={20} py={3}>
        <Center>
          <Text fontSize={{ base: 10, lg: 12 }} fontWeight="bold">
            Copyright © 2023 BR5 Turbo. Todos os direitos reservados.
          </Text>
        </Center>
      </Box>
    </>
  );
};

export default Footer;
