import {
  AspectRatio,
  Box,
  Button,
  Center,
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import Carousel from "react-multi-carousel";

const socialProofConfig = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const meetingProofConfig = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ShowcaseSection: React.FC = () => {
  const videoShowcase = [
    {
      video: "Iwe3zWRjAg8",
      personName: "Marcus Campos",
      description: `Já ganha mais de R$ 2.000,00 por mês com BR5 Turbo.`,
    },
    {
      video: "TCD54mYHEos",
      personName: "Juliana Barros",
      description: `Realizou o sonho de fazer sua primeira faculdade com o projeto da renda vitalícia da BR5 Turbo.`,
    },
    {
      video: "-Bv6SEGGUcc",
      personName: "Bruno Paes",
      description: `Saiu da falencia financeira e hoje vive com a renda vitalícia da BR5 Turbo.`,
    },
  ];

  const socialProof = [
    "./assets/images/testimonial/1.webp",
    "./assets/images/testimonial/2.webp",
    "./assets/images/testimonial/W6TKDFb.webp",
    "./assets/images/testimonial/3.webp",
  ];

  const meetingProof = [
    "./assets/images/testimonial/mUcoMTy.webp",
    "./assets/images/testimonial/GzoKsVq.webp",
    "./assets/images/testimonial/2U91WV3.webp",
    "./assets/images/testimonial/yCO5SGg.webp",
  ];

  return (
    <>
      <Box
        bg="black"
        w="100vw"
        py={{ base: 14, lg: 20 }}
        position="relative"
        bgImg="./assets/images/bg-showcasesection.webp"
        bgSize="cover"
        bgPosition="center"
        px={{ base: 3, lg: 0 }}
      >
        <Text mb={14} textAlign="center" variant="title2">
          Conheça quem já vive de Renda Vitalícia
        </Text>
        <SimpleGrid
          justifyContent="space-between"
          px={{ base: 0, lg: 10 }}
          columns={{ base: 1, lg: 3 }}
          w="100%"
        >
          {videoShowcase.map(({ video, personName, description }) => (
            <Box key={video} px={{ base: 5, lg: 10 }}>
              <AspectRatio
                margin="0 auto"
                width={{
                  base: "250px",
                  lg: "280px",
                  xl: "300px",
                  "2xl": "450px",
                }}
                height={{ base: "300px", lg: "300px", xl: "300px" }}
              >
                <iframe
                  src={`https://www.youtube.com/embed/${video}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </AspectRatio>

              <Text
                my={10}
                textAlign="center"
                variant="title2"
                fontSize={{ base: 16, lg: 24 }}
              >
                {personName}
              </Text>
              <Text
                my={10}
                textAlign="center"
                variant="description"
                fontSize={{ base: 14, lg: 20 }}
              >
                {description}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
        <Center my={{ base: 10, lg: 20 }}>
          <Button
            display={{ base: "none", lg: "inline-flex" }}
            w={{ base: "80vw", lg: "40vw" }}
            onClick={() => (window.location.href = "#contact-us")}
          >
            QUERO ACELERAR MINHA RENDA
          </Button>
          <Button
            display={{ base: "inline-flex", lg: "none" }}
            w={{ base: "80vw", lg: "40vw" }}
            onClick={() => (window.location.href = "#contact-us")}
          >
            QUERO ACELERAR <br />
            MINHA RENDA
          </Button>
        </Center>

        {!!socialProof.length && (
          <>
            <Text
              mb={10}
              mt={{ base: 10, lg: 20 }}
              textAlign="center"
              variant="title2"
            >
              Casos reais de Renda gerada
            </Text>

            <Box px={{ base: 5, lg: 20 }}>
              <Carousel
                swipeable={false}
                draggable={false}
                keyBoardControl={true}
                ssr={true}
                infinite={true}
                responsive={socialProofConfig}
              >
                {socialProof.map((link) => (
                  <Center key={link} px={2} mx={3}>
                    <Image src={link} maxW="250px" />
                  </Center>
                ))}
              </Carousel>
            </Box>
          </>
        )}

        {!!meetingProof.length && (
          <>
            <Text my={10} textAlign="center" variant="description">
              Procuramos pessoas que queiram aumentar suas fontes de renda!
            </Text>
            <Text textAlign="center" variant="title2">
              4.732 Vidas já impactadas
            </Text>

            <Box px={5} mt={20}>
              <Carousel
                swipeable={false}
                draggable={false}
                keyBoardControl={true}
                ssr={true}
                infinite={true}
                responsive={meetingProofConfig}
              >
                {meetingProof.map((link) => (
                  <Center key={link} mx={3}>
                    <Image src={link} />
                  </Center>
                ))}
              </Carousel>
            </Box>
          </>
        )}

        <Text mt={14} textAlign="center" variant="description">
          Faça como muitos empresários bem sucedidos e tenha outras fontes de
          renda
        </Text>
      </Box>
    </>
  );
};

export default ShowcaseSection;
