import { lazy } from "react";
import "./App.css";
import "animate.css";

import WhatsappButton from "../components/common/WhatsappButton";

import ShowcaseSection from "./ShowcaseSection";
import Footer from "../components/Footer";

const Navbar = lazy(() => import("../components/Navbar"));
const FeelAwsnerCardsSection = lazy(() => import("./FeelAwsnerCardsSection"));
const FeelAskCardsSection = lazy(() => import("./FeelAskCardsSection"));
const CompanyPresentationSection = lazy(
  () => import("./CompanyPresentationSection")
);
const ContactUsSection = lazy(() => import("./ContactUsSection"));
const HelpCardsSection = lazy(() => import("./HelpCardsSection"));
const MentorsSection = lazy(() => import("./MentorsSection"));
const MethodStepsSection = lazy(() => import("./MethodStepsSection"));
const ServicesPresentationSection = lazy(
  () => import("./ServicesPresentationSection")
);
const ThumbVideoSection = lazy(() => import("./ThumbVideoSection"));
const WelcomeSection = lazy(() => import("./WelcomeSection"));

export const App = () => (
  <>
    <Navbar />
    <FeelAwsnerCardsSection />
    <FeelAskCardsSection />
    <ThumbVideoSection />
    <WelcomeSection />

    <HelpCardsSection />
    <MethodStepsSection />
    <CompanyPresentationSection />
    <ServicesPresentationSection />

    <ShowcaseSection />
    <MentorsSection />
    <ContactUsSection />

    <Footer />
    <WhatsappButton />
  </>
);
